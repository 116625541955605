export class Product {
	id: number;
	name: string;
	price: number;
	additionalGroups: AdditionalGroup[];
	imageUrl?: string;
	observation?: string;

	static fromJson(json: any): Product {
		return {
			id: json.id,
			name: json.name,
			price: json.price,
			additionalGroups: (json.additionalGroups ?? []).map((item: any) =>
				AdditionalGroup.fromJson(item)
			),
			imageUrl: json.imageUrl,
			observation: json.observation,
		};
	}
}

enum PizzaAdditionalType {
	flavor = 0,
	crust = 1,
	additional = 2,
}

namespace PizzaAdditionalType {
	export function fromValue(type: number): PizzaAdditionalType {
		switch (type) {
			case 0:
				return PizzaAdditionalType.flavor;
			case 1:
				return PizzaAdditionalType.crust;
			case 2:
				return PizzaAdditionalType.additional;
			default:
				throw new Error(`Invalid pizza additional type: ${type}`);
		}
	}
}

export class AdditionalGroup {
	constructor(
		public id: number,
		public name: string,
		public position: number,
		public required: boolean,
		public repeatable: boolean,
		public min: number,
		public max: number,
		public pizzaAdditionalType: PizzaAdditionalType | null,
		public additionals: Additional[]
	) {}

	static fromJson(json: any): AdditionalGroup {
		return new AdditionalGroup(
			json.id,
			json.name,
			json.ordering,
			json.required,
			json.repeatable,
			json.min,
			json.max,
			json.type !== undefined && json.type !== null
				? PizzaAdditionalType.fromValue(json.type)
				: null,
			(json.additionals ?? [])
				.map((item: any) => ({ ...item, groupId: json.id }))
				.map((item: any) => Additional.fromJson(item))
		);
	}

	get isSingleChoice(): boolean {
		if (
			this.pizzaAdditionalType === null ||
			this.pizzaAdditionalType === PizzaAdditionalType.additional
		) {
			return this.min === 1 && this.max === 1;
		}
		return this.pizzaAdditionalType === PizzaAdditionalType.crust;
	}

	get chargeFullPrice() {
		return this.pizzaAdditionalType !== PizzaAdditionalType.flavor;
	}

	get isFlavor(): boolean {
		return this.pizzaAdditionalType === PizzaAdditionalType.flavor;
	}

	get isCrust(): boolean {
		return this.pizzaAdditionalType === PizzaAdditionalType.crust;
	}

	get isAdditional(): boolean {
		return this.pizzaAdditionalType === PizzaAdditionalType.additional;
	}

	get isGroupOfPizza(): boolean {
		return this.pizzaAdditionalType !== null;
	}
}

export class Additional {
	id: number;
	groupId: number;
	name: string;
	price: number;
	position: number;
	description: string;
	pizzaSizeFlavorId?: number;

	static fromJson(json: any): Additional {
		return {
			id: json.id,
			groupId: json.groupId,
			name: json.name,
			price: json.price ? json.price : 0,
			position: json.ordering,
			description: json.description,
			pizzaSizeFlavorId: json.pizzaSizeFlavorId,
		};
	}
}

import {
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useEstablishment } from '../../shared/contexts';
import {
	Additional,
	AdditionalGroup,
	OrderItemAdditional,
	Pizza,
	PizzaSize,
	Product,
} from '../../shared/entities';
import { AppError } from '../../shared/errors';
import { useDidUpdate } from '../../shared/hooks/did_update';
import { ProductRepository } from '../../shared/repositories';

type Props = {
	children: ReactNode;
	productRepository: ProductRepository;
};

type PropsPizzaContext = {
	state: {
		pizza: Pizza | null;
		product: Product | null;
		isLoading: boolean;
		isLoadingProduct: boolean;
		error: AppError | null;
		quantity: number;
		totalPrice: number;
		size: PizzaSize | null;
		additionals: Map<Additional, number>;
	};
	loadPizza: () => void;
	incrementQuantity: () => void;
	decrementQuantity: () => void;
	incrementAdditionalQuantity: (additional: Additional) => void;
	decrementAdditionalQuantity: (additional: Additional) => void;
	selectSize: (size: PizzaSize) => void;
	selectAdditional: (additional: Additional) => void;
	selectedAdditionals: () => OrderItemAdditional[];
	limitFlavorsReached: () => boolean;
	limitAdditionalsReached: (additional: Additional) => boolean;
	isCrustSelected: () => boolean;
	areRequiredAdditionalsSelected: () => boolean;
	totalAdditionalsOfGroupSelected: (groupId: number) => number;
	getGroupById: (id: number) => AdditionalGroup;
};

const DEFAULT_VALUE = {
	state: {
		pizza: null as Pizza | null,
		product: null as Product | null,
		isLoading: true,
		isLoadingProduct: false,
		error: null as AppError | null,
		quantity: 1,
		totalPrice: 0,
		size: null as PizzaSize | null,
		additionals: new Map<Additional, number>(),
	},
	loadPizza: () => {},
	incrementQuantity: () => {},
	decrementQuantity: () => {},
	decrementAdditionalQuantity: (additional: Additional) => {},
	incrementAdditionalQuantity: (additional: Additional) => {},
	selectSize: (size: PizzaSize) => {},
	selectAdditional: (additional: Additional) => {},
	selectedAdditionals: () => [] as OrderItemAdditional[],
	limitFlavorsReached: () => false,
	limitAdditionalsReached: () => false,
	isCrustSelected: () => false,
	areRequiredAdditionalsSelected: () => false,
	totalAdditionalsOfGroupSelected: (groupId: number) => 0,
	getGroupById: (id: number) => null as any,
};

export const PizzaContext = createContext<PropsPizzaContext>(DEFAULT_VALUE);

export const usePizza = (): PropsPizzaContext => useContext(PizzaContext);

export const PizzaContextProvider = ({
	children,
	productRepository,
}: Props) => {
	const config = useEstablishment().state.config;
	const [state, setState] = useState(DEFAULT_VALUE.state);

	async function loadPizza() {
		setState({ ...state, error: null, isLoading: true });
		try {
			const pizza = await productRepository.getPizza();
			setState({ ...state, pizza, isLoading: false });
		} catch (error) {
			setState({ ...state, error: error as AppError, isLoading: false });
		}
	}

	useEffect(() => {
		if (!state.pizza || !state.size || !state.product) {
			return setState({ ...state, totalPrice: 0 });
		}
		let additionalsTotal = selectedAdditionals().reduce(
			(acc, { total }) => acc + total,
			0
		);
		const basePizzaPrice = state.product.price || 0;
		const total = (basePizzaPrice + additionalsTotal) * state.quantity;

		setState({ ...state, totalPrice: total });
	}, [state.size, state.quantity, state.additionals]);

	function getGroupById(id: number): AdditionalGroup {
		return state.product!.additionalGroups.find((group) => group.id === id)!;
	}

	useEffect(() => {
		return () => {
			state.additionals.clear();
		};
	}, []);

	function incrementQuantity(): void {
		if (state.quantity === 99) return;
		const quantity = state.quantity + 1;
		setState({ ...state, quantity });
	}

	function decrementQuantity(): void {
		if (state.quantity === 1) return;
		const quantity = state.quantity - 1;
		setState({ ...state, quantity });
	}

	function limitFlavorsReached() {
		const totalFlavorsSelected = Array.from(state.additionals)
			.filter(([additional]) => getGroupById(additional.groupId).isFlavor)
			.reduce((acc, [, quantity]) => acc + quantity, 0);
		return totalFlavorsSelected >= (state.size?.flavorsQtt || 0);
	}

	function limitAdditionalsReached(additional: Additional) {
		const group = getGroupById(additional.groupId);
		if (!group.repeatable && state.additionals.getOr(additional, 0) > 0) {
			return true;
		}
		return totalAdditionalsOfGroupSelected(group.id) >= group.max;
	}

	function incrementAdditionalQuantity(additional: Additional) {
		const group = getGroupById(additional.groupId);
		if (
			(group.isFlavor && limitFlavorsReached()) ||
			(!group.isFlavor && limitAdditionalsReached(additional))
		) {
			return;
		}
		const current = state.additionals.getOr(additional, 0);
		const additionals = state.additionals.set(additional, current + 1);
		setState({ ...state, additionals: new Map(additionals) });
	}

	function decrementAdditionalQuantity(additional: Additional) {
		const current = state.additionals.get(additional);
		if (!current || current <= 0) return;
		const additionals = state.additionals.set(additional, current - 1);
		setState({ ...state, additionals: new Map(additionals) });
	}

	async function selectSize(size: PizzaSize) {
		const isSelected = state.size === size;
		setState({
			...state,
			error: null,
			size: isSelected ? null : size,
			additionals: new Map(),
			product: null,
		});
		if (isSelected) {
			return;
		}
	}

	useDidUpdate(() => {
		if (state.size) {
			setState({ ...state, isLoadingProduct: true });
			productRepository
				.getProduct(state.size.product.id)
				.then((product) => {
					for (const group of product.additionalGroups) {
						for (const additional of group.additionals) {
							state.additionals.set(additional, 0);
						}
					}
					setState({
						...state,
						product,
						isLoadingProduct: false,
						additionals: new Map(state.additionals),
					});
				})
				.catch((error) => {
					setState({
						...state,
						isLoadingProduct: false,
						error: error as AppError,
					});
				});
		}
	}, [state.size]);

	function selectAdditional(additional: Additional) {
		const isSelected = state.additionals.get(additional) === 1;
		if (!isSelected) {
			const group = getGroupById(additional.groupId);
			if (group.isCrust) {
				const groups = state.product!.additionalGroups.filter((g) => g.isCrust);
				const groupIds = groups.map((g) => g.id);
				for (const entry of Array.from(state.additionals.keys())) {
					if (groupIds.includes(entry.groupId)) {
						state.additionals.set(entry, 0);
					}
				}
			} else {
				for (const entry of Array.from(state.additionals.keys())) {
					if (entry.groupId === additional.groupId) {
						state.additionals.set(entry, 0);
					}
				}
			}
		}
		state.additionals.set(additional, isSelected ? 0 : 1);
		setState({ ...state, additionals: new Map(state.additionals) });
	}

	function totalAdditionalsOfGroupSelected(groupId: number): number {
		const additionals = Array.from(state.additionals).filter(
			([additional]) => additional.groupId === groupId
		);
		return additionals.reduce((acc, [, quantity]) => acc + quantity, 0);
	}

	function selectedAdditionals(): OrderItemAdditional[] {
		const additionals = Array.from(state.additionals).filter(
			([, quantity]) => quantity > 0
		);

		const grouped = [] as {
			group: AdditionalGroup;
			additionals: OrderItemAdditional[];
		}[];

		let mostExpensiveFlavorPrice: number | undefined;
		if (config.chargeForMostExpensivePizzaFlavor) {
			mostExpensiveFlavorPrice = additionals
				.filter(([additional]) => getGroupById(additional.groupId).isFlavor)
				.reduce((acc, [additional]) => {
					return additional.price > acc ? additional.price : acc;
				}, 0);
		}

		for (const [additional, quantity] of additionals) {
			const group = getGroupById(additional.groupId);
			const index = grouped.findIndex((item) => item.group.id === group.id);

			let price: number;
			let total: number;
			if (group.chargeFullPrice) {
				price = additional.price;
				total = price * quantity;
			} else {
				price = mostExpensiveFlavorPrice ?? additional.price;
				total = (price / state.size!.flavorsQtt) * quantity;
			}

			const orderItemAdditional = new OrderItemAdditional(
				additional.id,
				additional.groupId,
				additional.name,
				price,
				quantity,
				total,
				additional.position,
				additional.pizzaSizeFlavorId,
				group.isCrust ? 'Borda' : group.isAdditional ? 'Adicional' : ''
			);
			if (index >= 0) {
				grouped[index].additionals.push(orderItemAdditional);
				grouped[index].additionals = grouped[index].additionals.sort(
					(a, b) => a.position - b.position
				);
			} else {
				grouped.push({ group, additionals: [orderItemAdditional] });
			}
		}
		return grouped
			.sort((a, b) => a.group.position - b.group.position)
			.map((item) => item.additionals)
			.flat();
	}

	function areRequiredAdditionalsSelected(): boolean {
		return Array.from(state.additionals)
			.map(([{ groupId }]) => getGroupById(groupId))
			.filter((group) => !group.isFlavor && group.required)
			.every((group) => {
				return totalAdditionalsOfGroupSelected(group.id) >= group.min;
			});
	}

	function isCrustSelected(): boolean {
		const crustAdditionals = Array.from(state.additionals).filter(
			([{ groupId }]) => getGroupById(groupId).isCrust
		);
		// Se não houver adicionais de borda, significa que a pizza não possui opção de borda
		if (crustAdditionals.length === 0) return true;
		return crustAdditionals.some(([, quantity]) => quantity > 0);
	}

	return (
		<PizzaContext.Provider
			value={{
				state,
				loadPizza,
				incrementQuantity,
				decrementQuantity,
				incrementAdditionalQuantity,
				decrementAdditionalQuantity,
				selectSize,
				selectAdditional,
				selectedAdditionals,
				limitFlavorsReached,
				limitAdditionalsReached,
				isCrustSelected,
				areRequiredAdditionalsSelected,
				totalAdditionalsOfGroupSelected,
				getGroupById,
			}}
		>
			{children}
		</PizzaContext.Provider>
	);
};
